<template>
  <!-- navigation START -->
  <div id="nav">
    <div class="main-navigation">
      <!-- logo START -->
      <div class="logo logo-centered">
        <router-link :to="'/'">
          <img
            width=""
            height="142"
            src="@/assets/images/logo-dorfladen-full.png"
            v-if="$route.name !== 'screensaver'"
          />
          <img
            width=""
            height="142"
            src="@/assets/images/logo-dorfladen-full.png"
            v-else
          />
        </router-link>
      </div>
      <!-- logo END -->
    </div>
  </div>
  <!-- navigation END -->
</template>

<script>
export default {
  name: 'app-navigation',
  data() {
    return {
    };
  },
};
</script>
